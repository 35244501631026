<template>
  <v-card flat width="400" class="mx-auto">
    <v-card-title>
      <span class="headline">Basic Setting</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-col cols="10" class="text-center mx-auto pb-0">
      <v-avatar size="200">
        <img :src="imageUrl" height="150" v-if="imageUrl" />
        <img
          v-else
          :src="dummyImg"
          class="pointer"
          @click="showImageDialog = true"
          alt="dummyuser"
        />
      </v-avatar>
      <v-text-field
        placeholder="Select Image"
        @click="pickFile"
        v-model="imageName"
        prepend-icon="attach_file"
        @click:prepend="pickFile"
        autocomplete="off"
        outlined
        readonly
        class="mt-4"
        dense
      ></v-text-field>
      <input
        type="file"
        accept="image/*"
        ref="image"
        @change="onFilePicked"
        style="display: none"
      />
    </v-col>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text elevation="0" to="/dashboard">CANCEL</v-btn>
      <v-btn
        color="primary"
        text
        elevation="0"
        @click="uploadProfileImage"
        :disabled="disableButton"
      >
        UPLOAD
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import profileAPI from "@/services/profileAPI.js";
import dummyImg from "../../assets/images/dummyProfile.jpeg";

export default {
  data: () => ({
    userDetail: {},
    disableButton: true,
    dummyImg: dummyImg,
    imageName: "",
    imageUrl: "",
    imageFile: "",
    type: "",
  }),

  methods: {
    async uploadProfileImage() {
      let uploadedData = {
        fileName: "profilePic",
        mimeTypeId: this.type,
        partyId: this.userDetail.partyId,
        uploadedData: this.imageUrl.replace(`data:${this.type};base64,`, ""),
      };
      try {
        await profileAPI.uploadProfileImage(uploadedData);
        this.$root.$emit("CHANGE_PROFILE_IMAGE", {
          imageData: this.imageUrl,
        });
        location.href = "/dashboard";
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Successfully profile updated",
            color: "success",
          });
        }, 100);
      } catch (error) {
        console.log("error+++++", error);
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: error.data.messageDetail,
            color: "error",
          });
        }, 100);
        this.isLoading = false;
      }
    },

    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const files = e.target.files;
      this.imageName = "";
      this.imageFile = "";
      this.imageUrl = "";
      this.type = "";
      if (files[0] !== undefined) {
        this.type = files[0].type;
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
          this.disableButton = false;
        });
      }
    },

    async getUserDetail() {
      let userData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
      };
      try {
        let response = await profileAPI.getUserDetail(userData);
        if (response.profileImageData) {
          this.imageUrl = `data:image/jpg;base64,${response.profileImageData}`;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
  },

  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getUserDetail();
  },
};
</script>

<style scoped>
.right-btn {
  margin-left: 2%;
}
</style>